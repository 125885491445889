<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                  Policy
<!--                    <div class="card">-->
<!--                        <div class="card-header d-flex justify-content-between">-->
<!--                            <div class="header-title">-->
<!--                                <h4 class="card-title">What is Lorem Ipsum?</h4>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="card-body">-->
<!--                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been-->
<!--                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley-->
<!--                                of type and scrambled it to make a type specimen book. It has survived not only five centuries,-->
<!--                                but also the leap into electronic typesetting, remaining essentially unchanged. It was-->
<!--                                popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,-->
<!--                                and more recently with desktop publishing software like Aldus PageMaker including versions of-->
<!--                                Lorem Ipsum.</p>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PrivacyPolicy'
}
</script>
